/* eslint-disable import/order */
import Screen from "@alpine-collective/toolkit-screen";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";
import * as Sentry from "@sentry/browser";
import emailSpellChecker from "@zootools/email-spell-checker";
import Alpine from "alpinejs";
import { Tooltip } from "./components";

window.htmx = require("htmx.org");

require("htmx.org/dist/ext/sse");

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://9284254105354939bf2bc79f434ab019@o1428391.ingest.sentry.io/4504338651873280",
  });
}

Alpine.plugin(Screen);
Alpine.plugin(collapse);
Alpine.plugin(intersect);

document.addEventListener("alpine:init", () => {
  Alpine.data("Tooltip", Tooltip);

  /**
   * Product configuration form with price calculation
   */
  Alpine.data("ItemForm", ({ price, currency }) => ({
    init() {
      this.currency = currency;
      this.price = price;
      this.addons = {};
      this.sale = null;

      // currently selected size
      this.sizeId = null;
      this.sizes = {};
    },
    registerSale(sale = { amount, percentage, valid_sizes, include_addons }) {
      this.sale = sale;
    },
    registerSize(size) {
      this.sizes[size.id] = size;
      if (size.enabled) {
        this.sizeId = size.id;
      }
    },
    selectSize(id) {
      this.sizeId = id;
    },
    registerAddon(addon) {
      this.addons[addon.id] = addon;
    },
    toggleAddon(id, enabled) {
      this.addons[id].enabled = enabled;

      const group = this.addons[id].group;
      const maxChoices = this.addons[id].choices;

      if (maxChoices > 1) {
        const form = this.addons[id].el.form;
        const selected = form.querySelectorAll(
          `input[name="addon-${group}"]:checked`
        );
        if (enabled && selected.length >= maxChoices) {
          // Disable inactive options to prevent further selections
          const unselected = form.querySelectorAll(
            `input[name="addon-${group}"]:not(:checked)`
          );
          for (let option of unselected) {
            option.disabled = true;
          }
        } else if (!enabled && selected.length <= maxChoices) {
          // Re-enable all options again
          const disabled = form.querySelectorAll(
            `input[name="addon-${group}"]:disabled`
          );
          for (let option of disabled) {
            option.disabled = false;
          }
        }
      } else if (enabled && maxChoices === 1) {
        for (let otherid of Object.keys(this.addons)) {
          const otherAddon = this.addons[otherid];
          if (id !== otherid && group === otherAddon.group) {
            if (otherAddon.el) {
              otherAddon.el.checked = false;
            }
            otherAddon.enabled = false;
          }
        }
      }
    },
    addonOptionText(id, text) {
      const price = this.addonPrice(id);
      if (price) {
        return `${text} (${this.format(price, true)})`;
      }
      return text;
    },
    addonDisabled(id) {
      if (this.sizeId === null) {
        return false;
      }
      const addon = this.addons[id];
      if (!addon) {
        return false;
      }
      if (addon.sizes && addon.sizes.length) {
        return !addon.sizes.includes(this.sizeId);
      }
      return false;
    },
    addonGroupDisabled(id) {
      if (this.sizeId === null) {
        return false;
      }
      for (let addonId of Object.keys(this.addons)) {
        if (this.addons[addonId].group === id) {
          let addon = this.addons[addonId];
          if (addon.sizes && addon.sizes.length) {
            return !addon.sizes.includes(this.sizeId);
          }
          return false;
        }
      }
      return false;
    },
    addonPrice(id) {
      const addon = this.addons[id];
      const size = this.currentSize;
      if (this.addonDisabled(id)) {
        return 0;
      }
      if (!size || addon.fixed) {
        return addon.price;
      }
      let addonPrice = addon.price * size.addon_multiplier + size.addon_offset;
      if (this.currency === "Fr.") {
        addonPrice = Math.round(addonPrice / 0.05) * 0.05;
      }
      return addonPrice;
    },
    addonPriceText(id) {
      const price = this.addonPrice(id);
      if (price === 0) {
        return "";
      }
      return this.format(price, true);
    },
    get currentSize() {
      return this.sizes[this.sizeId];
    },
    get currentPrice() {
      let price = this.price;

      const size = this.currentSize;

      if (size) {
        price *= size.price_multiplier;
        price += size.price_offset;
        if (this.currency === "Fr.") {
          price = Math.round(price / 0.05) * 0.05;
        }
      }

      let addonsPrice = 0;
      for (let addonId in this.addons) {
        let addon = this.addons[addonId];
        if (addon.enabled) {
          addonsPrice += this.addonPrice(addonId);
        }
      }
      if (
        this.sale &&
        (this.sale.valid_sizes.length === 0 ||
          this.sale.valid_sizes.includes(this.sizeId))
      ) {
        if (this.sale.include_addons) {
          price += addonsPrice;
        }
        if (this.sale.amount) {
          price -= this.sale.amount;
        } else if (this.sale.percentage) {
          price *= 1 - this.sale.percentage / 100;
        }
        if (!this.sale.include_addons) {
          price += addonsPrice;
        }
        return Math.round(price / 0.05) * 0.05;
      }
      return price + addonsPrice;
    },
    format(amount, withSign) {
      const sign = amount > 0 ? "+" : "-";
      if (amount < 0) {
        amount = Math.abs(amount);
      }
      return `${withSign ? sign : ""}${this.currency} ${amount.toFixed(2)}`;
    },
  }));

  /**
   * Require at least one checkbox
   */
  Alpine.data("CheckboxRequired", (el, message) => ({
    checkboxes: null,
    count: 0,
    checkedCount: 0,

    init() {
      this.checkboxes = el.querySelectorAll('[type="checkbox"]');
      this.count = this.checkboxes.length;
      if (!this.count) return;
      for (let checkbox of this.checkboxes) {
        checkbox.addEventListener("change", this.onChange.bind(this));
        if (checkbox.checked) {
          this.checkedCount++;
        }
      }
      this.validate();
    },
    onChange(event) {
      if (event.target.checked) {
        this.checkedCount++;
      } else {
        this.checkedCount--;
      }
      this.validate();
    },
    validate() {
      const errorMessage = this.checkedCount ? "" : message;
      this.checkboxes[0].setCustomValidity(errorMessage);
    },
  }));

  Alpine.data("EmailSpellCheck", (id) => ({
    el: document.getElementById(id),
    suggestion: null,

    init() {
      this.el.addEventListener("blur", () => {
        if (this.el.checkValidity()) {
          this.showSuggestion();
        }
      });
      if (this.el.ariaInvalid) {
        this.showSuggestion();
      }
    },
    showSuggestion() {
      const enteredEmail = this.el.value;
      const suggestedEmail = emailSpellChecker.run({
        email: enteredEmail,
        domains: [
          "outlook.com",
          "yahoo.com",
          "live.com",
          ...emailSpellChecker.POPULAR_DOMAINS,
        ],
        secondLevelDomains: ["hotmail", "gmx"],
      });
      if (suggestedEmail) {
        this.suggestion = suggestedEmail.full;
      } else {
        this.suggestion = null;
      }
    },
    applySuggestion() {
      if (this.suggestion) {
        plausible("Fix Email Spelling");
        this.el.value = this.suggestion;
        this.suggestion = null;
      }
    },
  }));
});

Alpine.start();
